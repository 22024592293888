.header {
    width: 100%;
    height: 7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #3498db;
    color: white;
    padding: 0 10%;
  }
  .logo{
    width: 7rem;
  }
  .logoContainer{
    display: flex;
  }
  .btn{
    float: right;
  }