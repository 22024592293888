footer{
    width: 100%;
    background-color: #3498db;
    color: white;
    position: absolute;
    bottom: 0;
}
.footer-container{
    display: flex;
    justify-content: space-around;
    letter-spacing: 0.3rem;
}
.footer-container h3 span{
    letter-spacing: normal;
    color: #145E8F;
}