.form {
  margin: 1rem 0;
  /* height: 19rem; */
  /* overflow: scroll; */
  }
  
  .form button {
    font: inherit;
    cursor: pointer;
    background-color: #3498DB;
    border: 1px solid #3498DB;
    color: white;
    padding: 0.25rem 2rem;
    border-radius: 20px;
    font-weight: bold;
  }
  
  .form button:hover,
  .form button:active {
    background-color: #145E8F;
    border-color: #145E8F;
  }
  .control {
    margin-bottom: 0.5rem;
  }
  
  .control label {
    font-weight: bold;
    margin-bottom: 0.25rem;
    display: block;
  }
  
  .control input {
    font: inherit;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 20rem;
    max-width: 100%;
  }
  
  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
  
  .actions button {
    font: inherit;
    color: #fff;
    cursor: pointer;
    background-color: #3498DB;
    border: none;
    border-radius: 25px;
    padding: 0.5rem 2rem; 
  }
  
  .actions button:hover,
  .actions button:active {
    background-color: #145E8F;
  }
  
  .actions .submit {
    border: 1px solid #5a1a01;
    background-color: #5a1a01;
    color: white;
  }
  
  .actions .submit:hover,
  .actions .submit:active {
    background-color: #145E8F;
  }