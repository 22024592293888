.controls {
    display: flex;
    padding: 1rem;
}
.controls button {
    width: 9rem;
    margin: 0.5rem;
}
.controls input[type="checkbox"] {
    cursor: pointer;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #3498db;
    border-radius: 4px;
    background-color: #fff;
    margin-right: 0.5rem;
}

.controls label {
    display: flex;
    align-items: center;
    margin: 0.5rem;
    cursor: pointer;
}
.controls input[type="checkbox"]:checked  {
    background-color: #3498db;
}
/* .ag-theme-alpine {
    --ag-foreground-color: rgb(126, 46, 132);
    --ag-background-color: rgb(249, 245, 227);
    --ag-header-foreground-color: rgb(204, 245, 172);
    --ag-header-background-color: rgb(209, 64, 129);
    --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
    --ag-header-column-resize-handle-color: rgb(126, 46, 132);
  
    --ag-font-size: 17px;
    --ag-font-family: monospace;
  } */