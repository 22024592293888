.login {
    width: 90%;
    max-width: 40rem;
    margin: 2rem auto;
    padding: 2rem;
  }
  
  
  .actions {
    text-align: center;
  }
  .error{
    text-align: center;
    color: red;
    padding: 0.5rem;
  }
  
  