* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: sans-serif;
  color: black;
  background-color: #f0f0f0;
}

button {
  font: inherit;
  cursor: pointer;
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
}

button:hover,
button:active {
  background-color: #145E8F;
  border-color: #145E8F;
}
a:link,
a:visited,
a:hover,
a:active {
text-decoration: none;
color: inherit;
}
footer
{
  position: relative !important;
}